export interface ServiceByWorkshopType {
  serviceCode: string;
  serviceName: string;
  serviceCategoryCode: string;
  workshopTypeCode: string;
}

export interface Service {
  serviceCode: string;
  serviceName: string;
  serviceCategoryCode: string;
  workshopTypeCode?: string;
  serviceCategoryName?: string;
}

export interface Day {
  dayCode: number;
  dayName: string;
}

export interface VehicleCatalog {
  code: string;
  desc: string;
  modelYears?: number[];
  brandCode?: string;
}

export interface VehicleBrand {
  code: string;
  desc: string;
}

export interface VehicleModel {
  code: string;
  desc: string;
  brandCode: string;
}
export interface ServiceCategory {
  serviceCategoryCode: string;
  serviceCategoryName: string;
}

export interface VehicleBrand {
  code: string;
  desc: string;
  modelYears: number[];
}

/* export interface VehicleModel {
  Code: string;
  Desc: string;
  BrandCode: string;
} */

export interface AppointmentStatus {
  code: string;
  desc: string;
}

export interface Role {
  roleCode: string;
  roleName: string;
}

export interface VehicleMainPurpose {
  vehicleMainPurposeCode: string;
  vehicleMainPurposeDesc: string;
}

export interface VehicleLicensePlateState {
  vehicleLicensePlateStateCode: string;
  vehicleLicensePlateStateDesc: string;
}

//Get Calls EnrollmentQuiz

export interface QuizSection {
  sectionName: string;
  sectionCode: string;
  sectionDesc: string;
  questions: QuizQuestions[];
}

export interface QuizQuestions {
  code: string;
  desc: string;
  answers: QuizAnswers[];
}
export interface QuizAnswers {
  code: string;
  desc: string;
  htmlId: string;
}

export interface QuizChecklist {
  sectionDesc: string;
  sectionName: string;
  options: QuizAnswers[];
}


export interface IPackageType {
  servicePackageTypeCode: string;
  servicePackageTypeDesc: string;
  packageItems: {
    packageItemCode: string;
    packageItemDesc: string;
  }[];
}
export interface IPackageCategory {
  servicePackageCode: string;
  servicePackageDesc: string;
  iconPath: string;
  types: IPackageType[];
  searchType: IPackageType;
  htmlID: string;
}

export interface IServiceRalatedFailure {
  srvcTypeFailRelatedId: string;
  srvcTypeFailRelatedDesc: string;
}
export interface IServiceCategory {
  serviceTypeCode: string;
  serviceTypeName: string;
  iconPath: string;
  htmlId: string;
  relatedFailures: IServiceRalatedFailure[]
}

/**
 * 
 * @param path package or service iconPath
 * @param prefix package or service
 * @param onErrorCallback 
 * @returns string with the CkUI Icon Name
 */
export const translatePathToIcon = (path: string, prefix: "package" | "service", onErrorCallback: Function): string => {
  try {
    return `${prefix}-${path.replace(".png", "")}`
  } catch (e) {
    console.error("e");
    if (!!path === false) {
      onErrorCallback();
    }
    return "";
  }

}

export interface IVehicleZone {
  vehicleZonesId: string;
  vehicleZonesDesc: string;
}