export interface notificationStatusInt  {
    notificationActionCode: string,
    statusCode: string,
    status: string,
    variable: string,
}

export interface appointmentStatusInt {
    statusCode: string,
    status: string,
    variable: string
}

export const appointmentStatus : appointmentStatusInt[] = [
    {
        statusCode: 'APPO',
        status: 'Cita confirmada',
        variable: '--exxon-blue-70'
    },
    {
        statusCode: 'CANCE',
        status: 'Cancelado',
        variable: '--red-dark'
    },
    {
        statusCode: 'CLOS' ,
        status: 'Entregado',
        variable: '--blue'
    },
    {
        statusCode: 'CREAT',
        status: 'Confimarción del cliente pendiente',
        variable: '--orange'
    },
    {
        statusCode: 'DIAG' ,
        status: 'En diagnósito',
        variable: '--black-50'
    },
    {
        statusCode: 'PEND' ,
        status: 'Confirmación Pendiente',
        variable: '--red'
    },
    {
        statusCode: 'PROG',
        status: 'Reparando',
        variable: '--black-40'
    },
    {
        statusCode: 'READY',
        status:  'Terminado',
        variable: '--exxon-blue-60'
    },
    {
        statusCode: 'RECEP',
        status:  'Recepción',
        variable: '--black-70'
    }
]

export const notificationStatus: notificationStatusInt[] = [
    {
        notificationActionCode: 'APPOINTMENTAPRROVED',
        statusCode: 'APPO',
        status: 'Cita confirmada',
        variable: 'exxon-blue-70'
    },
    {
        notificationActionCode: 'APPOINTMENTRECEPTION',
        statusCode: 'RECEP',
        status: 'Recepcion',
        variable: 'black-70'
    },
    {
        notificationActionCode: 'APPOINTMENTDIAG',
        statusCode: 'DIAG',
        status: 'En diagnostico',
        variable: 'black-50'
    },
    {
        notificationActionCode: 'APPOINTMENTPROG',
        statusCode: 'PROG',
        status: 'Reparando',
        variable: 'black-40'
    },
    {
        notificationActionCode: 'APPOINTMENTREADY',
        statusCode: 'READY',
        status: 'Terminado',
        variable: 'exxon-blue-60'
    },
    {
        notificationActionCode: 'APPOINTMENTCLOSED',
        statusCode: 'CLOS',
        status: 'Entregado',
        variable: 'blue'
    },
    {
        notificationActionCode: 'APPOINTMENTCANCE',
        statusCode: 'CANCE',
        status: 'Cancelado',
        variable: 'red-dark'
    },
    {
        notificationActionCode: 'WORKSHOPREPLY',
        statusCode: 'RECEP',
        status: 'Entregado',
        variable: 'black-70'
    },
    {
        notificationActionCode: 'CONSUMERREPLY',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'APPOINTMENTPEND',
        statusCode: 'PEND',
        status: 'Confirmacion pendiente',
        variable: 'red'
    },
    {
        notificationActionCode: 'CONSUMERDOUBT',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'CHATENDED',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'WORKSHOPAPPROVED',
        statusCode: 'CREAT',
        status: 'Confirm. Pendiente cliente',
        variable: 'orange'
    },
    {
        notificationActionCode: 'WORKSHOPREPLYQUERY',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'WORKSHOPAPPTRESCH',
        statusCode: 'CREAT',
        status: 'Confirm. Pendiente cliente',
        variable: 'orange'
    },
    {
        notificationActionCode: 'QUOTEREADY',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATEWORKSHOP',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATEUSER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATEPASSWORD',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGNEWAPPOINTMENT',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATEAPPOINTMENT',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGMIGRATEWORKSHOP',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGRESETWORKSHOP',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGNEWSERVICEPACKAGE',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATESERVICEPACKAGE',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGDELETEWORKSHOP',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGMIGRATEAPPOINTMENT',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGNEWUSER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGNEWSUPERUSER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATESUPERUSER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATEROLES',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATEWORKSHOPUSERS',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGDELETEUSER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGSENDSURVEY',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGNEWRIDESHARE',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATERIDESHARE',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATECLAIM',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGUPDATEWORKSHOPFEEDBACK',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGADDUSERFEEDBACK',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGDELETEWORKSHOPFEEDBACK',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGHIDEMESSAGEFEEDBACK',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGDELETEMESSAGEFEEDBACK',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGADDCOMENTFEEDBACK',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SULOGSHOWMESSAGEFEEDBACK',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'WORKSHOPWELCOMETRAINING',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'WORKSHOPCONFIRMSUBMIT',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'WORKSHOPNEXTSTEPS',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'WORKSHOPREGISTER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'INVITECOWORKER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    { 
        notificationActionCode: 'APPROVEAPPOINTMENT',
        statusCode: 'PEND',
        status: 'Confirmacion pendiente',
        variable: 'red'
    },
    { 
        notificationActionCode: 'APPROVEAPPOINTMENTREMINDER',
        statusCode: 'PEND',
        status: 'Confirmacion pendiente',
        variable: 'red'
    },
    { 
        notificationActionCode: 'NOTIFYAPPOINTMENTSCHEDULE',
        statusCode: 'PEND',
        status: 'Confirmacion pendiente',
        variable: 'red'
    },
    { 
        notificationActionCode: 'NOTIFYAPPOINTMENTSCHEDULEREMINDER',
        statusCode: 'PEND',
        status: 'Confirmacion pendiente',
        variable: 'red'
    },
    { 
        notificationActionCode: 'CONFIRMVEHICLERECEPTION',
        statusCode: 'RECEP',
        status: 'Recepcion',
        variable: 'black-70'
    },
    { 
        notificationActionCode: 'WORKSHOPGETSVEHICLE',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    { 
        notificationActionCode: 'DELIVERVEHICLECUSTOMER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    { 
        notificationActionCode: 'REQUESTFEEDBACK',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    { 
        notificationActionCode: 'CONSUMERQUERY',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    { 
        notificationActionCode: 'CONVERSARIONFINISHED',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    { 
        notificationActionCode: 'QUOTEORQUERYREQUEST',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    { 
        notificationActionCode: 'WORKSHOPACTIVITYSUMARY',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    { 
        notificationActionCode: 'VALIDATIONNUMBER',
        statusCode: 'CREAT',
        status: 'Confirm. Pendiente cliente',
        variable: 'orange'
    },
    {
        notificationActionCode: 'CONFIRMATIONSUBMITTED',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'COMPLETEREGISTRATION',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'CONFIRMAPPOINTMENT',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'REMINDERAPPOINTMENT',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'APPTCANCELEDBYWORKSHOP',
        statusCode: 'CANCE',
        status: 'Cancelado',
        variable: 'red-dark'
    },
    {
        notificationActionCode: 'APPTCANCELEDBYCONSUMER',
        statusCode: 'CANCE',
        status: 'Cancelado',
        variable: 'red-dark'
    },
    {
        notificationActionCode: 'APPTRESCHEDULEBYWORKSHOP',
        statusCode: 'CREAT',
        status: 'Confirm. Pendiente cliente',
        variable: 'orange'
    },
    {
        notificationActionCode: 'APPTRESCHEDULEBYCONSUMER',
        statusCode: 'PEND',
        status: 'Confirmacion pendiente',
        variable: 'red'
    },
    {
        notificationActionCode: 'EARNINGPOINTS',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'INVITEREGISTRATION',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'APPTCONFIRMREMINDER',
        statusCode: 'CREAT',
        status: 'Confirm. Pendiente cliente',
        variable: 'orange'
    },
    {
        notificationActionCode: 'CONFIRMAPPOINTMENTUNLOGGED',
        statusCode: 'APPO',
        status: 'Cita confirmada',
        variable: 'exxon-blue-70'
    },{
        notificationActionCode: 'REMINDERAPPOINTMENTNEXTDAY',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'RESCHEDULEAPPOINTMENTREMINDER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'CONTINUECONVERSATION',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'APPOINTMENTREAMINDER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'SCHEDULEREQUESTAVAILABLE',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'WORQUOTEORQUERYREQUEST',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'DATAVALIDATION',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'MIGRATIONNOTIFICATION',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'MIGRATIONSUCCESSFULLY',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'QUOTESOLICITATIONWORKSHOPREMINDER',
        statusCode: 'CLOS',
        status: '',
        variable: 'blue'
    },
    {
        notificationActionCode: 'APPTCANCELEDNOTIFYWORKSHOP',
        statusCode: 'CANCE',
        status: 'Cancelado',
        variable: 'red-dark'
    }
];

export const filterNotificationStatus = (key: 'notificationActionCode' | 'statusCode' | 'status' | 'variable', value: string) => {
    return notificationStatus.filter(item => item[key] === value)[0]
}

export const filterAppointmentStatus = (key: 'statusCode' | 'status' | 'variable', value: string) => {
    return appointmentStatus.filter(item => item[key] === value)[0]
}
